<template>
  <div>
    <div class="all-height d-flex justify-center">
      <div class="all-width">
        
        <v-card
          :loading="loading"
          :disabled="loading"
          loader-height="2"
          class=""
        >
          <v-card-text class="d-flex align-center pb-2">
            <v-tooltip bottom content-class="tooltip-bottom">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="shadow-off"
                  icon
                  small
                  @click="$nova.gotoLink({ path: '/bot/project' })"
                  ><v-icon>mdi-arrow-left</v-icon></v-btn
                >
              </template>
              <span>Back to Bots</span>
            </v-tooltip>
            <v-tooltip bottom content-class="tooltip-bottom">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="shadow-off"
                  icon
                  small
                  @click="getBots()"
                  ><v-icon>mdi-refresh</v-icon></v-btn
                >
              </template>
              <span>Refresh</span>
            </v-tooltip>
            <div v-if="config.review">
              <v-chip
                small
                label
                color="success"
                class=""
                v-if="metadataList.stage === 2"
                >Closed</v-chip
              >
              <v-chip
                small
                label
                text
                color="warning"
                class=""
                v-if="metadataList.stage === 1"
                >Review</v-chip
              >
              <v-chip
                small
                label
                text
                color="info"
                class=""
                v-if="metadataList.stage === 0"
                >Open</v-chip
              >
            </div>
            <v-spacer></v-spacer>
          </v-card-text>
          <v-card-text class="pt-0">
            <div class="d-flex align-center">
              <div class="subtitle-1 font-weight-bold ml-2">
                {{ config.headingtext || metadataList.botname }}
              </div>
              <v-spacer></v-spacer>
              <tr>
                <td class="text-center">
                  <v-btn
                    color="primary"
                    class="ml-2 shadow-off"
                    small
                    @click="startRun()"
                  >
                    <v-icon class="mr-1">mdi-play</v-icon>
                    Start Run
                  </v-btn>
                  <v-btn
                    color="error"
                    class="ml-2 shadow-off"
                    v-if="metadataList.stage === 1 && config.review"
                    small
                    @click="open()"
                  >
                    <v-icon class="mr-1">mdi-arrow-left</v-icon>
                    Send back to preparer
                  </v-btn>
                  <v-btn
                    color="success"
                    class="ml-2 shadow-off"
                    v-if="metadataList.stage === 1 && config.review"
                    small
                    @click="close()"
                  >
                    <v-icon class="mr-1">mdi-lock</v-icon>
                    Review & Close
                  </v-btn>
                  <v-btn
                    color="info"
                    class="ml-2 shadow-off"
                    v-if="metadataList.stage === 2 && config.review"
                    small
                    @click="open()"
                  >
                    <v-icon class="mr-1">mdi-lock-open-variant</v-icon>
                    Re-open Analysis
                  </v-btn>
                  <v-btn
                    color="warning"
                    class="ml-2 shadow-off"
                    v-if="metadataList.stage === 0 && config.review"
                    small
                    @click="sendReview()"
                  >
                    <v-icon class="mr-1">mdi-check</v-icon>
                    Send for Review
                  </v-btn>
                </td>
              </tr>
            </div>
            <div class="ml-2" v-if="config.period">
              <span class=""
                ><v-icon>mdi-calendar-range</v-icon> Period :
              </span>
              <span class=""
                >From : {{ $nova.formatDate(metadataList.from) }}
              </span>
              <span class="">To : {{ $nova.formatDate(metadataList.to) }}</span>
              <div class="d-flex"></div>
            </div>
          </v-card-text>
          <v-card-text class="pt-0 pb-0">
            <v-alert
              text
              border="left"
              class="py-2 my-0"
              type="info"
              :value="true"
              v-if="hasParameters && !savedParameters"
            >
              Save Input Parameters to proceed with the file upload.
            </v-alert>
            <v-alert
              text
              border="left"
              class="py-2 my-0"
              type="info"
              :value="true"
              v-else-if="hasFiles && !savedFiles"
            >
              Upload all the Files to start the run.
            </v-alert>
            <v-alert
              text
              border="left"
              class="py-2 my-0"
              type="warning"
              :value="true"
              v-else-if="hasFiles && !processedFiles"
            >
              Waiting for files to process for starting the analysis.
            </v-alert>
          </v-card-text>
          <v-card-text class="pt-0 pb-0">
            <v-tabs v-model="tab" bg-color="primary" height="40">
              <v-tab :key="0" class="text-transform-none justify-start"
                ><v-icon class="mr-2">mdi-server-security</v-icon
                >Verifications</v-tab
              >
              <v-tab
                :key="1"
                v-if="hasParameters"
                class="text-transform-none justify-start"
                ><v-icon class="mr-2">mdi-play-box-multiple-outline</v-icon
                >Input Parameters</v-tab
              >
              <v-tab :key="2" class="text-transform-none justify-start"
                ><v-icon class="mr-2">mdi-cloud-upload-outline</v-icon
                >Files</v-tab
              >
              <v-tab
                :key="3"
                class="text-transform-none justify-start"
                v-if="Object.keys(rundetails).length > 0"
                @click="generateAnnexurelist(selectedresulttab)"
                ><v-icon class="mr-2">mdi-format-list-bulleted</v-icon
                >Result</v-tab
              >
            </v-tabs>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-text>
            <v-tabs-items v-model="tab">
              <v-tab-item :key="0">
                <div v-for="(item, i) in setupList.verification" :key="i">
                  <div class="d-flex py-2 px-4 hoverable">
                    <div class="font-weight-bold body-2 mr-4">{{ i + 1 }}</div>
                    <div class="mr-2">
                      <v-tooltip
                        bottom
                        content-class="tooltip-bottom"
                        v-if="(rundetails[item._id] || {}).stage === 1"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="success"
                            >mdi-check-circle</v-icon
                          >
                        </template>
                        <span>Completed</span>
                      </v-tooltip>
                      <v-tooltip
                        bottom
                        content-class="tooltip-bottom"
                        v-else-if="(rundetails[item._id] || {}).stage === 4"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="error"
                            >mdi-radiobox-marked</v-icon
                          >
                        </template>
                        <span>Currently running</span>
                      </v-tooltip>
                      <v-tooltip bottom content-class="tooltip-bottom" v-else>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="info"
                            >mdi-timer-sand</v-icon
                          >
                        </template>
                        <span>Pending</span>
                      </v-tooltip>
                    </div>
                    <div>{{ item.name }}</div>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item :key="1" v-if="hasParameters">
                <!-- <div class="d-flex">
                  <v-spacer></v-spacer>
                  <v-btn
                    small
                    class="mb-2 mr-2"
                    color="primary"
                    @click="saveInputParameters()"
                    >Save Input Parameters</v-btn
                  >
                </div> -->

                <!-- Custom compnents -->
                <!-- <div v-for="(item, i) in setupList.parameter" :key="i">
                  <div class="d-flex pa-2 hoverable align-start">
                    <span class="mr-4">{{ i + 1 }}.</span>
                    <div class="">
                      <span class="font-weight-bold">{{ item.code }}</span>
                      <v-icon>mdi-circle-small</v-icon>
                      <v-tooltip right content-class="tooltip-right" v-if="item.hidden">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" class="mr-2" color="success">mdi-security</v-icon>
                        </template>
                        <span>Admin Only</span>
                      </v-tooltip>
                      <span>{{ item.name }}</span>
                    </div>
                    <v-spacer></v-spacer>
                    <div>
                      <div style="width: 170px">
                        <div
                          v-if="item.type === 'number'"
                          class="parameter-input"
                        >
                          <lb-number
                            v-model="inputparametersvalues[item._id]"
                            :hidedetails="true" :disabled="item.hidden"
                          />
                        </div>
                        <div
                          v-else-if="item.type === 'string'"
                          class="parameter-input"
                        >
                          <lb-string
                            v-model="inputparametersvalues[item._id]"
                            :hidedetails="true" :disabled="item.hidden"
                          />
                        </div>
                        <div
                          v-else-if="item.type === 'date'"
                          class="parameter-input"
                        >
                          <lb-date
                            v-model="inputparametersvalues[item._id]"
                            :hidedetails="true" :disabled="item.hidden"
                          />
                        </div>
                        <div
                          v-else-if="item.type === 'year'"
                          class="parameter-input"
                        >
                          <lb-year
                            v-model="inputparametersvalues[item._id]"
                            :hidedetails="true" :disabled="item.hidden"
                          />
                        </div>
                        <div
                          v-else-if="item.type === 'month'"
                          class="parameter-input" :disabled="item.hidden"
                        >
                          <lb-month
                            v-model="inputparametersvalues[item._id]"
                            :hidedetails="true"
                          />
                        </div>
                        <div
                          v-else-if="item.type === 'time'"
                          class="parameter-input" :disabled="item.hidden"
                        >
                          <lb-time
                            v-model="inputparametersvalues[item._id]"
                            :hidedetails="true"
                          />
                        </div>
                        <div
                          v-else-if="item.type === 'daterange'"
                          class="parameter-input" :disabled="item.hidden"
                        >
                          <lb-daterange
                            v-model="inputparametersvalues[item._id]"
                            :hidedetails="true"
                          />
                        </div>
                        <div
                          v-else-if="item.type === 'timerange'"
                          class="parameter-input" :disabled="item.hidden"
                        >
                          <lb-timerange
                            v-model="inputparametersvalues[item._id]"
                            :hidedetails="true"
                          />
                        </div>
                        <div
                          v-else-if="
                            [
                              'numberarray',
                              'stringarray',
                              'datearray',
                              'yeararray',
                              'montharray',
                              'timearray',
                              'daterangearray',
                              'timerangearray',
                            ].indexOf(item.type) > -1
                          "
                          class="parameter-input"
                        >
                          <lb-list
                            :type="item.type"
                            v-model="inputparametersvalues[item._id]"
                            :hidedetails="true" :disabled="item.hidden"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <v-divider></v-divider>
                </div> -->

                <InputParam
                  :parameter="setupList.parameter"
                  :parametervalue="inputparametersvalues"
                  :userType="IsReveiw"
                  :apiParam="apiRouteParam"
                >
              </InputParam>
              </v-tab-item>
              <v-tab-item :key="2">
                <!-- <div
                  v-for="(item, i) in setupList.file"
                  :key="i"
                  class="hoverable"
                >
                  <div class="px-2 py-1">
                    <div class="d-flex align-center">
                      <div class="font-weight-bold">
                        <span class="mr-2">{{ i + 1 }}.</span>
                        <span>{{ item.name }}</span>
                        <v-icon
                          color="success"
                          class="ml-2"
                          v-if="(uploadDetails[item._id] || {}).processed === 1"
                          >mdi-check-circle</v-icon
                        >
                        <v-icon color="info" class="ml-2" v-else
                          >mdi-timer-sand</v-icon
                        >
                      </div>
                      <v-spacer></v-spacer>
                      <div v-if="fileprocesserrors[item._id]" class="ml-2">
                        <span class="error--text">{{
                          fileprocesserrors[item._id] || "Unknown Error"
                        }}</span>
                      </div>
                      <span
                        v-if="uploadDetails[item._id]"
                        @click="
                          $nova.downloadFile(
                            (uploadDetails[item._id] || {})._id
                          )
                        "
                        class="mx-2 cursor-pointer blue--text text--darken-2 caption"
                      >
                        <v-icon class="mr-1 blue--text text--darken-2"
                          >mdi-file</v-icon
                        >
                        {{ uploadDetails[item._id].uploadname }}
                      </span>
                      <div
                        v-if="fileprocessstage[item._id] === 1 && counter"
                        class="ml-2"
                      >
                        <v-chip small color="info" :value="true" label>
                          Validating
                        </v-chip>
                      </div>
                      <div
                        v-else-if="
                          (uploadDetails[item._id] || {}).processed === 0
                        "
                        class="ml-2"
                      >
                        <v-chip small label color="warning" :value="true">
                          Processing
                        </v-chip>
                      </div>
                      <div
                        v-else-if="fileprocessstage[item._id] === 2 && counter"
                        class="ml-2"
                      >
                        <v-chip small label color="info" :value="true">
                          Uploading
                        </v-chip>
                      </div>
                      <lb-file
                        v-if="(uploadDetails[item._id] || {}).processed !== 0"
                        v-model="filesdata[item._id]"
                        label=""
                        class="py-0 my-0 ml-2"
                        :drag="false"
                        hidedetails
                        :loading="
                          [1, 2].indexOf(fileprocessstage[item._id]) > -1
                        "
                        :loadingpercent="
                          (Number(fileuploadpercent[item._id]) || 0) +
                          counter * 0
                        "
                        :displayname="false"
                        accept=".xlsx,.xls,.xlsm,.xlsb,.csv"
                        @change="FileProcess(item._id, item.columns)"
                      ></lb-file>
                    </div>
                    <div>
                      <div class="grey--text text--darken-1">
                        Required columns:
                      </div>

                      <div
                        v-if="(setupList.fileconfig[item._id] || {}).columnmap"
                      >
                        <v-chip
                          x-small
                          class="mr-1 mb-1"
                          v-for="(item, i) in (
                            setupList.fileconfig[item._id] || {}
                          ).columnmap"
                          :key="i"
                          >{{ item[1] || "Unknown" }}</v-chip
                        >
                      </div>

                      <div v-else>
                        <v-chip
                          x-small
                          class="mr-1 mb-1"
                          v-for="(item, i) in setupList.file[i].columns"
                          :key="i"
                          >{{ item.name }}</v-chip
                        >
                      </div>
                    </div>
                  </div>
                  <v-divider class=""></v-divider>
                </div>  -->
                
                <FilesCom
                  :files="setupList.file"
                  :filevalues="uploadDetails"
                  :apifile="apiRouteFiles"
                  :fileproerr="fileprocesserrors"
                  :fileprostage="fileprocessstage"
                  :fileuppercent="fileuploadpercent"
                  :filesdataPros="filesdata"
                  :fileconfig="setupList.fileconfig"
                />
              </v-tab-item>
              <v-tab-item :key="3" v-if="Object.keys(rundetails).length > 0">
                <div>
                  <div class="d-flex my-2">
                    <div style="min-width: 250px; max-width: 250px">
                      <template v-for="(v, k) in verificationlist">
                        <div
                          :key="k"
                          :class="`${
                            selectedresulttab === k
                              ? 'border-left-primary'
                              : 'border-left-transparent'
                          }`"
                        >
                          <div
                            @click="
                              selectedresulttab = k;
                              generateAnnexurelist(v);
                            "
                            :class="`px-2 py-1 d-flex align-start cursor-pointer hoverable ${
                              selectedresulttab === k ? 'grey lighten-4' : ''
                            }`"
                          >
                            <div class="mr-2">
                              <v-tooltip
                                bottom
                                content-class="tooltip-bottom"
                                v-if="(rundetails[v._id] || {}).stage === 1"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="success"
                                    >mdi-check-circle</v-icon
                                  >
                                </template>
                                <span>Completed</span>
                              </v-tooltip>
                              <v-tooltip
                                bottom
                                content-class="tooltip-bottom"
                                v-else-if="
                                  (rundetails[v._id] || {}).stage === 4
                                "
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" color="error"
                                    >mdi-radiobox-marked</v-icon
                                  >
                                </template>
                                <span>Currently running</span>
                              </v-tooltip>
                              <v-tooltip
                                bottom
                                content-class="tooltip-bottom"
                                v-else
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" color="info"
                                    >mdi-timer-sand</v-icon
                                  >
                                </template>
                                <span>Pending</span>
                              </v-tooltip>
                            </div>
                            <div>
                              {{ v.name }} asdf asdf asdf asdf asdf asdf S
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                    <div class="flex-grow-1 px-4" style="min-width: 0">
                      <div class="d-flex">
                        <lb-dropdown
                          label="Annexures"
                          v-model="selectedannexure"
                          :items="annexurelist"
                          @change="
                            readAnnexure(
                              selectedannexure,
                              (verificationlist[selectedresulttab] || {})._id
                            )
                          "
                        />
                        <v-spacer class="flex-grow-1"></v-spacer>
                        <v-btn icon><v-icon>mdi-download</v-icon></v-btn>
                      </div>
                      <div class="" v-if="currentannexuredata.length > 0">
                        <v-simple-table dense class="" :fixed-header="true">
                          <template v-slot:default>
                            <thead>
                              <tr class="grey lighten-3">
                                <template
                                  v-for="(v, k) in currentannexuredata[0]"
                                >
                                  <th
                                    class="grey lighten-3"
                                    :key="k"
                                    v-if="k !== '_id'"
                                  >
                                    {{ (k || "").replaceAll("_", " ") }}
                                  </th>
                                </template>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(v, k) in currentannexuredata"
                                :key="k"
                              >
                                <template
                                  v-for="(vv, kk) in currentannexuredata[0]"
                                >
                                  <td :key="kk" v-if="kk !== '_id'">
                                    {{
                                      typeof v[kk] === "number"
                                        ? $nova.formatNumber(v[kk].toFixed(0))
                                        : v[kk]
                                    }}
                                  </td>
                                </template>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </div>
                      <div v-else class="text-center grey--text title pa-6">
                        No data available
                      </div>
                    </div>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </div>
    </div>
    
  </div>
</template>
<script>
import InputParam from "../../../views/conserve/InputParamerter.vue";
import FilesCom from "../../../views/conserve/FileUploads.vue";
export default {
  data() {
    return {
      IsReveiw: true,
      fileprocessstage: {},
      savedParameters: false,
      hasParameters: false,
      processedFiles: false,
      savedFiles: false,
      hasFiles: false,
      dataTable: [],
      counter: 0,
      fileuploaddata: {},
      fileprocesserrors: {},
      fileuploadpercent: {},
      filesdata: {},
      inputparametersvalues : {},
      BotIDC: null,
      loading: false,
      tab: "1",
      allVerifications: [],
      verificationlist: [],
      metadataList: {},
      setupList: {},
      fileConfigInfo: {},
      uploadDetails: {},
      rundetails: {},
      annexurelist: [],
      selectedresulttab: 0,
      selectedannexure: "",
      currentannexuredata: [],
      annexuredata: {},
      apiRouteParam:
        "/v2/bot/analysis/storeinputparameter/" + this.$route.params.botID,
      apiRouteFiles:
        "/v2/bot/analysis/storeinputparameter/" + this.$route.params.botID,
      // fileStatusInfo: {},
    };
  },
  props: {
    project: {
      type: String,
      default: "",
    },
    embed: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: function(){
        return {}
      },
    },
  },
  components: {
    InputParam,
    FilesCom,
  },
  created() {
    this.BotIDC = this.project || this.$route.params.botID;
    console.log(this.BotIDC, "BotIDC");
    this.getBots();
  },
  methods: {
    getBots() {
      this.loading = true;
      this.fileprocesserrors = {};
      this.fileprocessstage = {};
      this.fileuploadpercent = {};
      this.axios
        .post("/v2/bot/project/getmetadata/" + this.BotIDC)
        .then((ele) => {
          if (ele.data.status === "success") {
            if (ele.data.data.length > 0) {
              this.allVerifications = ele.data.data[0] || {};
              this.metadataList = this.allVerifications.metadata || {};
              this.setupList = this.allVerifications.setup || {};
              this.inputparametersvalues = this.metadataList.inputparameter || {};
              this.fileConfigInfo = this.setupList.fileconfig || {};
              this.verificationlist = this.setupList.verification || {};
              this.uploadDetails = this.allVerifications.uploaddetails || {};

              // set run details
              this.rundetails = {};
              for (const i of this.allVerifications.rundetails || []) {
                this.rundetails[i.verification] = i;
              }
              this.generateAnnexurelist();

              // to check if inputparameter are available and if the same is saved
              this.savedParameters = false;
              if((this.setupList.parameter || []).length > 0){
                this.hasParameters = true;
                let allparametersaved = true;
                for (let ele of this.setupList.parameter) {
                  if (!Object.hasOwnProperty.call((this.metadataList.inputparameter || {}), ele._id)) {
                    allparametersaved = false;
                  }
                }
                this.savedParameters = allparametersaved;
              } else {
                this.hasParameters = false;
              }

              // set default input parameter values
              let availableids = []
              for (const k in this.inputparametersvalues) {
                if (Object.hasOwnProperty.call(this.inputparametersvalues, k)) {
                  availableids.push(k);
                }
              }
              for (const i of this.setupList.parameter || []) {
                if (availableids.indexOf(i._id) === -1) {
                  if (
                    Object.hasOwnProperty.call(
                      this.setupList.inputparameterconfig || {},
                      i._id
                    )
                  )
                    this.inputparametersvalues[i._id] = (
                      this.setupList.inputparameterconfig[i._id] || {}
                    ).value;
                  else this.inputparametersvalues[i._id] = i.value;
                }
              }
  
              // to check if files are available and if the same is saved and also flag processing errors
              if((this.setupList.file || []).length > 0){
                this.hasFiles = true;
                let allfileprocessed = true;
                let allfilesaved = true;
                let availablefiles = [];
                for (const i of this.metadataList.files || []) {
                  availablefiles.push(i.fileid);
                }
                for (let ele of this.setupList.file) {
                  let id = ele._id;
                  if ((this.uploadDetails[id] || {}).processed !== 1) {
                    allfileprocessed = false;
                  }
                  else if((this.uploadDetails[id] || {}).processed === -1) this.fileprocesserrors[id] = "Error while processing file";
                  if(availablefiles.indexOf(id) === -1 || (this.uploadDetails[id].processed || 0) === -1) allfilesaved = false;
                }
                this.savedFiles = allfilesaved;
                this.processedFiles = allfileprocessed;
              } else {
                this.hasFiles = false;
              }
            } else throw new Error("No project found");
          } else {
            throw new Error("Error fetching data");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    readAnnexure(c, v) {
      if (this.annexuredata[c]) {
        this.currentannexuredata = this.annexuredata[c];
      } else {
        this.loading = true;
        Promise.resolve()
          .then(() => {
            return this.axios.post(
              "/v2/bot/analysis/readannexure/" +
                this.BotIDC +
                "/c/" +
                c +
                "/v/" +
                v
            );
          })
          .then((dt) => {
            if (dt.data.status === "success") {
              this.annexuredata[c] = dt.data.data;
              this.currentannexuredata = this.annexuredata[c];
            } else throw new Error(dt.data.message || "Error reading annexure");
          })
          .catch((err) => {
            this.$store.commit(
              "sbError",
              err.message || err || "Unknown error!"
            );
            this.currentannexuredata = [];
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    generateAnnexurelist: function (index = 0) {
      let ndata = [];
      let selectedverification = this.verificationlist[index] || {};
      let rundata = this.rundetails[selectedverification._id] || {};
      if (Object.keys(rundata).length > 0) {
        let annxdata = rundata;
        let hasannexure = false;
        console.log(annxdata);
        for (let i = 0; i < (annxdata.annexure || []).length; i++) {
          const el = (annxdata.annexure || [])[i];
          ndata.push({ name: el.name, value: el.collection });
          if (el.collection === this.selectedannexure) hasannexure = true;
        }
        if (!hasannexure) this.selectedannexure = ndata[0].value;
        this.readAnnexure(this.selectedannexure, selectedverification._id);
      }
      this.annexurelist = ndata;
    },
    sendReview() {
      this.loading = true;
      this.axios
        .post("/v2/bot/project/review/" + this.BotIDC)
        .then((ele) => {
          if (ele.data.status === "success") {
            console.log(ele.data.data, "qq");
            this.$store.commit("sbSuccess", "Project sent for review");
            this.getBots();
          } else throw new Error("Error while sending for review");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    startRun() {
      this.loading = true;
      this.axios
        .post("/v2/bot/analysis/startrun/" + this.BotIDC)
        .then((ele) => {
          if (ele.data.status === "success") {
            this.$store.commit("sbSuccess", "Run Started");
            this.getBots();
          } else throw new Error("Error starting run");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    open() {
      this.loading = true;
      this.axios
        .post("/v2/bot/project/open/" + this.BotIDC)
        .then((ele) => {
          if (ele.data.status === "success") {
            this.$store.commit("sbSuccess", "Project Re-opened");
            console.log(ele.data.data, "ss");
            this.getBots();
          } else throw new Error("Error re-opening project");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.loading = true;
      this.axios
        .post("/v2/bot/project/close/" + this.BotIDC)
        .then((ele) => {
          if (ele.data.status === "success") {
            this.$store.commit("sbSuccess", "Project Closed");
            console.log(ele.data.data, "aa");
            this.getBots();
          } else throw new Error("Error closing project");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveInputParameters() {
      this.loading = true;
      let objectInput = { inputparameter: this.inputparametersvalues };
      this.axios
        .post("/v2/bot/analysis/storeinputparameter/" + this.BotIDC, {
          data: objectInput,
        })
        .then((ele) => {
          if (ele.data.status === "success") {
            this.$store.commit("sbSuccess", "Input Parameters Updated");
            this.getBots();
          } else throw new Error("Error updating input parameters");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    FileProcess(id, columns) {
      console.log(this.fileprocessstage[id], "this.fileprocessstage[id]");
      if (
        this.filesdata[id] &&
        (this.fileprocessstage[id] === 0 ||
          this.fileprocessstage[id] === undefined)
      ) {
        let success = false;
        this.fileprocessstage[id] = 1;
        this.counter++;
        this.fileprocesserrors[id] = "";
        let file = this.filesdata[id];
        let config = this.fileConfigInfo[id];
        let columArr = [];
        for (const i of columns) {
          columArr.push([i.name, i.name]);
        }
        this.$nova
          .processConserveFile(file, config, columArr)
          .then(() => {
            this.fileprocessstage[id] = 2;
            this.counter++;
            return this.$nova.uploadFile(this.axios, file, {
              progressfunction: (e) => {
                let p = (e.loaded * 100) / e.total;
                this.fileuploadpercent[id] = p.toFixed(0);
                this.counter++;
                console.log(this.fileuploadpercent[id]);
              },
            });
          })
          .then((dt) => {
            if (dt.data.status === "success") {
              this.fileUpDetil = dt.data.data[0];

              return this.axios.post(
                "/v2/bot/analysis/storefile/" + this.BotIDC,
                {
                  data: { upload: this.fileUpDetil._id, file: id },
                }
              );
            } else throw new Error("Unable to upload file");
          })
          .then((dt) => {
            if (dt.data.status === "success") {
              this.fileUploadedDetilas = dt.data.data;
              success = true;
            } else throw new Error("Not Stored Project Detilas");
          })
          .catch((err) => {
            this.fileprocesserrors[id] = err.message || err || "Unknown error!";
            console.log(err);
          })
          .finally(() => {
            this.filesdata[id] = null;
            this.fileprocessstage[id] = 0;
            this.counter++;
            if (success) this.getBots();
          });
      }
    },
  },
};
</script>
